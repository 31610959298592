<template>
  <image-content show-navigation show-footer>
    <template #image-headline>
      <h1
        class="Headline is-size-4"
        v-t="'userRoles.professional'"
      />
    </template>

    <template #image>
      <figure>
        <img
          src="@/assets/images/avatar-professional.png"
          :alt="$t('userRoles.professional')"
        />
      </figure>
    </template>

    <template #image-below>
      <p>
        <strong
          class="Headline is-size-5 d-block"
          v-t="'registration.benefits'"
        />

        <ul>
          <li
            v-for="(item, index) in benefits"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
      </p>
    </template>

    <template #content>
      <div class="Content">
        <regapp-feedback
          v-if="registered"
          :is-fully="fullRegistration"
        >
          <template #body>
            <div v-html="translateWithMymedelString(`${responseMessageKey}.full`)" />
          </template>

          <template #footer>
            <br>
            <button class="ButtonLink" @click="openLogin">{{ $t('links.toLogin.label') }}</button>
          </template>
        </regapp-feedback>

        <step-list
          v-if="!registered"
          ref="steps"
          :steps="steps"
          :step="step"
          v-on:triggerPrevStep="this.prevStep()"
        />

        <Form
          v-if="!registered"
          v-slot="slotProps"
          ref="form"
          @submit="submitProfessionalRegistration"
          class="Step"
        >
          <!-- step 1 -->
          <transition :name="formTransition" mode="out-in">
            <div v-show="isStepOne">
              <strong
                class="Headline is-size-5 d-block"
                v-t="'registration.professional.title'"
              />
              <input-component :fieldName="fieldNames.email" :fieldLabel="emailFieldLabel" :rules="`required|email`" type="email" :errors="slotProps"/>
              <input-password-component
                :fieldName="fieldNames.password"
                :fieldLabel="passwordFieldLabel"
                :rules="`required|passwordMasterRule:@${fieldNames.email}`"
                :errors="slotProps"
              />
              <input-component
                :fieldName="fieldNames.passwordConf"
                :fieldLabel="passwordConfirmationFieldLabel"
                :rules="`required|passwordConfirm:@${fieldNames.password}`"
                type="password"
                :errors="slotProps"
              />
              <input-component :fieldName="fieldNames.firstName" :fieldLabel="firstNameFieldLabel" :rules="`required|max:20`" type="text" :errors="slotProps"/>
              <input-component :fieldName="fieldNames.lastName" :fieldLabel="lastNameFieldLabel" :rules="`required|max:20`" type="text" :errors="slotProps"/>

              <input-select-component
                :fieldName="fieldNames.profession"
                :options="professions"
                :fieldLabel="professionsFieldLabel"
              />

              <input-checkbox-component
                :fieldName="fieldNames.terms"
                :fieldLabel="$t('form.terms')"
                :errors="slotProps.errors[fieldNames.terms]"
              >
                <terms-of-use-and-privacy-policy-links />
              </input-checkbox-component>
            </div>
          </transition>
          <!-- step 1 -->
          <transition :name="formTransition" mode="out-in">
            <div v-show="isStepTwo">
              <strong
                class="Headline is-size-5 d-block"
                v-text="$t('registration.professional.clinicInformation')"
              />

              <input-component :fieldName="fieldNames.institution" :fieldLabel="institutionFieldLabel" :rules="isStepTwo ? `required|max:100` : ''" type="text" :errors="slotProps"/>
              <input-component :fieldName="fieldNames.street" :fieldLabel="streetFieldLabel" :rules="isStepTwo ? `required|max:100` : ''" type="text" :errors="slotProps"/>
              <input-component :fieldName="fieldNames.city" :fieldLabel="cityFieldLabel" :rules="isStepTwo ? 'required|max:50' : ''" type="text" :errors="slotProps"/>

              <input-select-component
                :fieldName="fieldNames.country"
                :options="countries"
                :fieldLabel="countryFieldLabel"
                :rules="isStepTwo ? 'required' : ''"
              />
            </div>
          </transition>

          <button
            class="Button form-button d-block"
            :class="{ 'is-grey': !slotProps.meta.valid }"
          >
            <span v-show="!isCheckingMail" v-text="$t(nextLabel)" />
            <simple-spinner
              :show="isCheckingMail"
              classNames="is-registration"
            />
          </button>

          <button
            type="button"
            v-if="step > 0"
            class="Button form-button is-link d-block"
            v-text="$t('form.prev')"
            @click="prevStep"
          />
        </Form>
        <p>
          {{ $t('form.alreadyAccount') }}
          <button class="ButtonLink" @click="openLogin">{{ $t('form.loginNow') }}</button>
        </p>

        <validation-error-message :errors="errors" ref="validationErrorMessage" />

        <sweet-modal icon="warning" ref="modalError">
          <div v-html="$t('registration.messages.internal:error')" />
        </sweet-modal>
      </div>
    </template>
  </image-content>
</template>
<script>
import registrationForm from '@/mixins/registrationForm.js'
import ValidationErrorMessage from '../components/ValidationErrorMessage.vue'
import localeMessages from '@/mixins/localeMessages'
import TermsOfUseAndPrivacyPolicyLinks from '../components/partials/TermsOfUseAndPrivacyPolicyLinks.vue'
import { Form } from 'vee-validate'
import InputComponent from '@/components/partials/InputComponent.vue'
import InputCheckboxComponent from '@/components/partials/InputCheckboxComponent.vue'
import InputPasswordComponent from '@/components/partials/InputPasswordComponent.vue'
import InputSelectComponent from '@/components/partials/InputSelectComponent.vue'

export default {
  name: 'RegisterProfessional',

  metaInfo () {
    return {
      title: this.$i18n.t('pages.professionalRegistration.meta.title')
    }
  },

  components: {
    ValidationErrorMessage,
    TermsOfUseAndPrivacyPolicyLinks,
    Form,
    InputComponent,
    InputCheckboxComponent,
    InputPasswordComponent,
    InputSelectComponent
  },

  mixins: [
    registrationForm,
    localeMessages
  ],

  mounted () {
    this.scrollUp()
  },

  computed: {
    professions () {
      return this.getOptionValues('dropdowns.professions', true)
    },

    benefits () {
      return this.$tm('registration.professional.benefits')
    },

    isStepOne () {
      return this.step === 0
    },

    isStepTwo () {
      return this.step === 1
    }
  },

  data () {
    return {
      errors: {},
      step: 0,
      steps: 2,
      userRole: null,
      country: null,
      confirmations: {
        terms: false
      },
      registered: false,
      hasError: false,
      isCheckingMail: false,
      fullRegistration: true,
      responseMessageKey: '',
      formTransition: 'form-step-left',
      fieldNames: {
        email: 'email',
        password: 'password',
        passwordConf: 'passwordConf',
        firstName: 'firstName',
        lastName: 'lastName',
        profession: 'profession',
        terms: 'terms',
        institution: 'institution',
        city: 'city',
        street: 'street',
        country: 'country'
      }
    }
  },

  watch: {
    step: function () {
      if (this.step === 0) {
        this.$refs.form.setFieldValue(this.fieldNames.passwordConf, '')
      }
    }
  },

  methods: {
    async submitProfessionalRegistration (values) {
      // if there are more steps go to next page
      let tempValues = Object.assign({}, this.$refs.form.getValues())
      if (this.step + 1 < this.steps) {
        this.$refs.form.resetForm({ errors: {}, values: tempValues })
        return this.nextStep()
      }

      let success = false
      this.fullRegistration = true
      success = await this.createUserProfileAndUpgrade(this.getProfessionalRequestObject(values))

      if (success) {
        this.responseMessageKey = `registration.messages.success`
        this.registered = true
        this.scrollUp()
      }
    },
    getProfessionalRequestObject (values) {
      let basicFields = this.getBasicRequestObject(values)
      return Object.assign(basicFields, {
        registrationType: 'professional',
        country: values[this.fieldNames.country].code,
        institutionName: values[this.fieldNames.institution]?.trim(),
        institutionStreet: values[this.fieldNames.street]?.trim(),
        institutionCity: values[this.fieldNames.city]?.trim()
      })
    },
    getBasicRequestObject (values) {
      return {
        registrationType: 'basic',
        email: values[this.fieldNames.email]?.trim(),
        firstName: values[this.fieldNames.firstName]?.trim(),
        lastName: values[this.fieldNames.lastName]?.trim(),
        password: values[this.fieldNames.password],
        userType: 'Professional',
        language: this.$i18n.locale ? this.$i18n.locale : 'en',
        userRole: values[this.fieldNames.profession].code,
        agreedToTermsAndConditions: values[this.fieldNames.terms],
        dateOfRegistration: new Date(),
        signupUrl: this.getSignupUrl()
      }
    }
  }
}
</script>
